import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { ISearchFilter } from '@shared/interface/search.interface';
import { IsampleList } from '@shared/list/sample.list';

@Injectable({
  providedIn: 'root',
})
export class HttpProjectService {
  private http = inject(HttpClient);
  private urlApi = environment.apiUrl + '/project';

  list = [];

  constructor() {
    this.init();
  }

  findAll(filter?: ISearchFilter) {
    return this.http.post<any>(`${this.urlApi}/all`, { ...filter });
  }

  init() {
    this.findAll().subscribe((resp) => {
      this.list = resp.data;
    });
  }

  getList() {
    const resp: IsampleList[] = [];
    Object.values(this.list).forEach((l: any) => {
      resp.push({ id: l.id, name: l.pry_Desc });
    });
    return resp;
  }
}
